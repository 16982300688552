













import { Component, Prop } from 'vue-property-decorator';
import CompanyCmsProfileBaseWidget from '@/components/company-cms/CompanyCmsProfileBaseWidget.vue';
import HeaderPanel from '@/views/adminPanel/company/tabs/HeaderPanel.vue';
import { mixins } from 'vue-class-component';
import VueRegisterStoreWidget from '@/utils/widgets/VueRegisterStoreWidget';

@Component({
  components: { HeaderPanel },
})
export default class CompanyCmsPageHeaderWidget extends mixins(CompanyCmsProfileBaseWidget, VueRegisterStoreWidget) {
  @Prop({ required: false })
  private route!: string;

  created(): void {
    this.setDataConfig();
  }
}
